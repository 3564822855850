import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const PrivacyPolicy = () => (
    <Layout>
        <Helmet>
            <title>Privacy Policy | ECCO Design Build</title>
            <meta name="description" content="Privacy Policy" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Privacy Policy</h1>
                    </header>

                    <p>No Mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.</p>
                    
                </div>
            </section>

        </div>

    </Layout>
)

export default PrivacyPolicy
